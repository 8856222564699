import React, { useState } from 'react';
import { Typography, Box, Table, TableRow, TableCell } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Button from 'components/inputs/ButtonM';
import { format as formatDateFns } from 'date-fns';
import { formatCurrency0, formatWeight, formatTime, formatDistance } from 'utils/utils';
import { useGState } from 'state/store';
import Select from 'react-select';

const useStyles = makeStyles(() => ({
  delivAsmntHolder: {
    display: 'flex',
    background: '#FFFFFF',
    marginStart: '1.2rem',
    marginEnd: '1.2rem',
    paddingTop: '1.563rem',
    paddingBottom: '1rem',
  },

  pickAndBtnHolder: {},

  picklistHolder: {
    width: '5rem',
    height: '4.688rem',
    borderRadius: 7,
    background: '#f1f3f7',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '0.938rem',
    paddingTop: '0.75rem',
    paddingBottom: '1.313rem',
  },
  textDate: {
    textAlign: 'center',
    color: '#9097a6',
    fontSize: '0.625rem',
  },

  textPickList: {
    color: '#31405c',
  },

  assignmentHolder: {
    width: '100%',
    marginStart: '1.063rem',
  },

  select: {
    width: '100%',
    height: '3.313rem',
    background: '#FFFFFF',
  },

  metricsHolder: {
    height: '4.5rem',
    //display: 'flex',
    background: 'rgba(139, 150, 164, 0.17)',
    // justifyContent: 'space-between',
    // alignItems: 'center',
    paddingStart: '1.2rem',
    paddingEnd: '1.2rem',
  },

  metricsInfoHolder: {
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    // flexWrap: 'wrap',
  },

  textMetricsInfo: {
    fontSize: '0.563rem',
    color: '#9097a6',
    textAlign: 'center',
  },
  textMetricsInfoValue: {
    color: '#31405c',
  },
}));

export default function AssignmentItem({
  assignment,
  primaryDaList,
  secondaryDaList,
  vehicleList,
  assign,
}) {
  const classes = useStyles();
  const permissions = useGState((s) => s.permission);
  const userHasAssignPermission = permissions.some((p) => p && p.code === 'tms.execution.assign');
  const { picklistId, date, picklist, metrics } = assignment;
  const { total, tripTime, tripDistance, sales, weight, volume } = metrics;
  const [selectedPrimaryAgent, setPrimaryAgent] = useState();
  const [selectedSecondaryAgents, setSecondaryAgents] = useState([]);
  const [selectedVehicle, setVehicle] = useState();

  function onPrimaryAgentSelected(value) {
    //console.log('Primary agent Selected : ', value);
    setPrimaryAgent(value);
  }

  function onSecondaryAgentSelected(value) {
    //console.log('Secondary agent Selected : ', value);
    setSecondaryAgents(value);
  }

  function onVehicleSelected(value) {
    //console.log('Vehicle Selected : ', value);
    setVehicle(value);
  }

  function onAssignCLicked() {
    //console.log('On Assigned clicked');
    // console.log('Assign Data : ', {
    //   assignmentDate: date,
    //   deliveryAgentId: selectedPrimaryAgent.value,
    //   picklistId: picklistId,
    //   secondaryDeliveryAgentIds: selectedSecondaryAgents.map((item) => {
    //     return item.value;
    //   }),
    //   vehicleId: selectedVehicle.value,
    // });
    assign({
      assignmentDate: date,
      deliveryAgentId: selectedPrimaryAgent.value,
      picklistId: picklistId,
      secondaryDeliveryAgentIds: selectedSecondaryAgents.map((item) => {
        return item.value;
      }),
      vehicleId: selectedVehicle.value,
    });
  }

  return (
    <div className="bg-white m-2 rounded-lg shadow">
      {/* Picklist & delivery assignment section */}
      <Box className={classes.delivAsmntHolder}>
        {/* Picklist & assign button holder */}
        <Box className={classes.pickAndBtnHolder}>
          {/* Picklist */}
          <Box className={classes.picklistHolder}>
            {/* <Typography className={classes.textDate}>{picklist.date}</Typography> */}
            <Typography className={classes.textDate}>{`${formatDateFns(
              new Date(picklist.date),
              'dd'
            )} ${formatDateFns(new Date(picklist.date), 'MMM')} ${formatDateFns(
              new Date(picklist.date),
              'yyyy'
            )}`}</Typography>
            <Typography
              className={classes.textPickList}
              classes={{ root: 'text-center text-sm mt-1.5 font-medium' }}
            >
              {picklist.name}
            </Typography>
          </Box>
          {/* Assign Button */}
          {selectedPrimaryAgent && selectedVehicle ? (
            <Button onClick={() => onAssignCLicked()} disabled={!userHasAssignPermission}>
              Assign
            </Button>
          ) : null}
        </Box>

        {/* Assignment section */}
        <Box className={classes.assignmentHolder}>
          {/* Delivery Agent select */}
          <div className="w-full">
            <Select
              placeholder="Delivery Agent"
              options={primaryDaList}
              value={selectedPrimaryAgent}
              onChange={(value) => onPrimaryAgentSelected(value)}
              isSearchable={true}
            />
          </div>
          {/* Second Delivery Agent multi-select */}
          <div className="w-full mt-2">
            <Select
              isMulti
              placeholder="Secondary Delivery Agent"
              options={secondaryDaList}
              value={selectedSecondaryAgents}
              onChange={(value) => onSecondaryAgentSelected(value)}
              isSearchable={true}
            />
          </div>
          {/* vehicle select */}
          <div className="w-full mt-2">
            <Select
              placeholder={
                picklist?.vehicleType ? `Planned - ${picklist?.vehicleType}` : 'Select Vehicle'
              }
              options={vehicleList}
              value={selectedVehicle}
              onChange={(value) => onVehicleSelected(value)}
              isSearchable={true}
            />
          </div>
        </Box>
      </Box>

      {/* metrics/plan info section */}
      <Table className={classes.metricsHolder}>
        <TableRow>
          {/* Value */}
          <TableCell className={classes.metricsInfoHolder}>
            <Typography className={classes.textMetricsInfo}>Value</Typography>
            <Typography
              className={classes.textMetricsInfoValue}
              classes={{ root: 'text-center text-xs mt-1.5 font-medium' }}
            >
              {formatCurrency0(sales)}
            </Typography>
          </TableCell>
          {/* weight */}
          <TableCell className={classes.metricsInfoHolder}>
            <Typography className={classes.textMetricsInfo}>Weight</Typography>
            <Typography
              className={classes.textMetricsInfoValue}
              classes={{ root: 'text-center text-xs mt-1.5 font-medium' }}
            >
              {formatWeight(weight)} kg
            </Typography>
          </TableCell>
          {/* Volume% */}
          <TableCell className={classes.metricsInfoHolder}>
            <Typography className={classes.textMetricsInfo}>Volume</Typography>
            <Typography
              className={classes.textMetricsInfoValue}
              classes={{ root: 'text-center text-xs mt-1.5 font-medium' }}
            >
              {Math.round((volume * 100) / picklist.maxVolume)} %
            </Typography>
          </TableCell>
          {/* Distance */}
          <TableCell className={classes.metricsInfoHolder}>
            <Typography className={classes.textMetricsInfo}>Distance</Typography>
            <Typography
              className={classes.textMetricsInfoValue}
              classes={{ root: 'text-center text-xs mt-1.5 font-medium' }}
            >
              {formatDistance(tripDistance)} km
            </Typography>
          </TableCell>
          {/* trip time */}
          <TableCell className={classes.metricsInfoHolder}>
            <Typography className={classes.textMetricsInfo}>Trip Time</Typography>
            <Typography
              className={classes.textMetricsInfoValue}
              classes={{ root: 'text-center text-xs mt-1.5 font-medium' }}
            >
              {formatTime(tripTime)}
            </Typography>
          </TableCell>
          {/* Drops */}
          <TableCell className={classes.metricsInfoHolder}>
            <Typography className={classes.textMetricsInfo}>Drops</Typography>
            <Typography
              className={classes.textMetricsInfoValue}
              classes={{ root: 'text-center text-xs mt-1.5 font-medium' }}
            >
              {total}
            </Typography>
          </TableCell>
        </TableRow>
      </Table>
    </div>
  );
}
