import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  MenuItem,
  Paper,
  Select as SelectMUI,
  Tab,
  Table,
  TableCell,
  TableRow,
  Tabs,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';
import Container from 'components/Container';
import Button from 'components/inputs/ButtonM';
import DatePicker from 'components/inputs/DatePicker';
import React, { useReducer, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import Select from 'react-select';
import { ga, useGDispatch, useGState } from 'state/store';
import fetch from 'utils/fetch';
import { formatDate, isBranch } from 'utils/utils';
import AssignedPickList from './AssignedPickList';
import assignmentReducer, { actions } from './assignmentReducer';
import PendingPickList from './PendingPickList';

const useStyles = makeStyles(() => ({
  dialogContainer: {
    width: '25.688rem',
    height: '25rem',
    borderRadius: 28,
  },
  headerContainer: {
    width: '100%',
  },
  textHeading: {
    fontSize: '1.125rem',
    color: '#1d3451',
    fontWeight: 'bold',
  },

  bodyContainer: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },

  datePicker: {
    width: '100%',
    height: '3.313rem',
    background: '#FFFFFF',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#dadee7',
    elevation: 5,
    marginTop: '2.438rem',
  },
  textDate: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
    color: '#31405c',
  },

  selectPicklist: {
    width: '100%',
    marginTop: '2rem',
    marginBottom: '2rem',
  },
}));

export default function DeliveryAssignment({ branchId }) {
  const classes = useStyles();
  const [state, dispatch] = useReducer(assignmentReducer, {});
  const { date, branch } = useGState((s) => ({ date: s.date, branch: s.branch }));
  const [value, setValue] = useState(0);
  const [isAddPlDialogVisible, setAddPlDialogVisibility] = useState(false);
  const [selectedOldPickList, setSelectedOldPickList] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [oldPicklists, setOldPickLists] = useState();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { refetch: refetchPickList } = useQuery(
    isBranch(branch) && ['delivery_assignment', { date, branchId: branchId || branch?.id }],
    (_, { date, branchId }) => fetch(`/delivery/assignment?date=${date}&branchId=${branchId}`),
    {
      onSuccess: (data) => {
        dispatch([actions.INIT, data]);
        refetchDeliveryAgents();
        refetchVehicles();
      },
    }
  );

  const [getPreviousPickList] = useMutation(
    ({ date, branchId }) =>
      fetch({
        url: '/delivery/plan/picklist',
        method: 'GET',
        params: {
          date: date,
          branchId: branchId,
        },
      }),
    {
      onSuccess: (data) => {
        //.log('Get prev pl call response : ', data);
        setOldPickLists(data);
      },
      // onError: (error) => {},
      onSettled: () => {},
    }
  );

  const [getPickListDetails] = useMutation(
    ({ picklistId }) =>
      fetch({
        url: `/delivery/assignment/picklist/${picklistId}`,
        method: 'GET',
        // params: {
        //   date: date,
        //   branchId: branchId,
        // },
      }),
    {
      onSuccess: (data) => {
        //console.log('Get picklist details : ', data);
        dispatch([actions.ADD_NEW_PICKLIST, data]);
      },
      // onError: (error) => {},
      onSettled: () => {},
    }
  );

  const { refetch: refetchDeliveryAgents } = useQuery(
    isBranch(branch) && ['delivery_agents', { branchId: branch?.id, designation: 'deliveryAgent' }],
    (_, { branchId, designation }) =>
      fetch(`/user?branchId=${branchId}&designation=${designation}`),
    {
      onSuccess: (data) => {
        //console.log('Da list response : ', data);
        dispatch([actions.PREPARE_DA_LIST, data]);
      },
      // onError: (error) => {},
    }
  );

  const { refetch: refetchVehicles } = useQuery(
    isBranch(branch) && ['vehicles', { branchId: branch?.id, designation: 'vehicle' }],
    (_, { branchId, designation }) =>
      fetch(`/user?branchId=${branchId}&designation=${designation}`),
    {
      onSuccess: (data) => {
        //console.log('Vehicle list response : ', data);
        dispatch([actions.PREPARE_VEHICLE_LIST, data]);
      },
      // onError: (error) => {},
    }
  );

  const [handleUnassign] = useMutation(
    (assignmentId) =>
      fetch({
        method: 'PUT',
        url: `/delivery/assignment/unassign?assignmentId=${assignmentId}`,
      }),
    {
      onSuccess: () => refetchPickList(),
    }
  );

  const [assign] = useMutation(
    ({ assignmentDate, deliveryAgentId, picklistId, secondaryDeliveryAgentIds, vehicleId }) =>
      fetch({
        url: '/delivery/assignment/assign',
        method: 'PUT',
        // params: {
        //   assignmentId: assignmentId,
        // },
        data: {
          assignmentDate: assignmentDate,
          deliveryAgentId: deliveryAgentId,
          picklistId: picklistId,
          secondaryDeliveryAgentIds: secondaryDeliveryAgentIds,
          vehicleId: vehicleId,
        },
      }),
    {
      onSuccess: () => {
        //console.log('Assign Call Response : ', data);
        refetchPickList();
      },
      // onError: (error) => {
      // console.log('Assign Call error : ', error);
      // },
      onSettled: () => {},
    }
  );
  function onPlanProfileSelected(value) {
    dispatch([actions.UPDATE_SELECTED_PLAN_PROFILE, value]);
  }

  function onAddPickListClicked() {
    setAddPlDialogVisibility(true);
  }

  function onClickAssignOldPickList() {
    //console.log('Selected old picklist : ', selectedOldPickList.id);
    getPickListDetails({ picklistId: selectedOldPickList.id });
    setAddPlDialogVisibility(false);
  }

  if (branch && !isBranch(branch))
    return (
      <Box m={2}>
        <Typography>
          This page is only available from a Branch. Please select a branch from the branch dropdown
        </Typography>
      </Box>
    );

  return (
    <Container cls="">
      <Paper>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs"
        >
          <Tab label="Assigned" />
          <Tab label="Pending" />
        </Tabs>
      </Paper>
      <PickListFilters
        value={value}
        state={state}
        date={date}
        onPlanProfileSelected={onPlanProfileSelected}
        onAddPickListClicked={onAddPickListClicked}
      />
      <Assignments
        value={value}
        date={date}
        assignments={state.filteredPickList}
        state={state}
        dispatch={dispatch}
        assign={(data) => assign(data)}
        handleUnassign={handleUnassign}
      />
      <Dialog
        open={isAddPlDialogVisible}
        keepMounted
        onClose={() => setAddPlDialogVisibility(false)}
      >
        <DialogContent className={classes.dialogContainer}>
          {/* -----Dialog Header--------*/}
          <Table className={classes.headerContainer}>
            <TableRow>
              <TableCell align="left">
                <Typography className={classes.textHeading}>Select Picklist</Typography>
              </TableCell>
              <TableCell align="right">
                <IconButton>
                  <CloseIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          </Table>
          {/* dialog body */}
          <div
            //className={classes.bodyContainer}
            className="md:box-content justify-center items-center"
          >
            {/* Date section */}
            <div>
              <DatePicker
                inputFormat="dd LLLL yyyy"
                value={selectedDate}
                className="w-full mt-10 bg-white text-theme rounded-lg border-none shadow self-center"
                onChange={(selectedDate) => {
                  //console.log('selected date: ', selectedDate);
                  setSelectedDate(selectedDate);
                  getPreviousPickList({
                    date: formatDate(selectedDate),
                    branchId: branch?.id,
                  });
                }}
              />
            </div>

            {/* Picklist section */}
            <SelectMUI
              className={classes.selectPicklist}
              classes={{ root: 'w-full bg-white rounded-lg shadow-lg' }}
              autoWidth={false}
              variant="filled"
              //defaultValue={()=> dispatch([actions.UPDATE_SELECTED_PLAN_PROFILE, { event.target.value }])}
              value={selectedOldPickList}
              onChange={(event) => setSelectedOldPickList(event.target.value)}
            >
              {oldPicklists && oldPicklists.length
                ? oldPicklists?.map((pl) => <MenuItem value={pl}>{pl.name}</MenuItem>)
                : null}
            </SelectMUI>

            {/* Assign button */}
            <div className="w-full text-center">
              <Button onClick={() => onClickAssignOldPickList()}>Assign</Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </Container>
  );
}

function PickListFilters(props) {
  const { state, value, onPlanProfileSelected, date, onAddPickListClicked } = props;
  const gDispatch = useGDispatch();

  return (
    <div className="flex flex-col md:flex-row md:items-center px-4 mt-6">
      <div>
        <DatePicker
          inputFormat="dd LLLL yyyy"
          value={date}
          className="bg-white text-theme rounded-lg border-none shadow"
          onChange={(selectedDate) => {
            if (selectedDate && !isNaN(selectedDate)) gDispatch([ga.DATE, selectedDate]);
          }}
        />
      </div>
      <div className="md:mx-10 mt-4 md:mt-0 flex-1">
        <Select
          options={state.profileList}
          value={state.selectedPlanProfile}
          onChange={onPlanProfileSelected}
          size="medium"
          isSearchable={true}
        />
      </div>
      <div className={`flex flex-1 mt-4 md:mt-0 md:justify-end`}>
        {value == 1 && (
          <Button
            className="bg-dark_blue mr-4 h-10 rounded-lg"
            onClick={() => onAddPickListClicked()}
          >
            Add PickList
          </Button>
        )}
        {value == 1 && (
          <Button className="bg-dark_blue h-10 rounded-lg" onClick={() => onAddPickListClicked()}>
            Map View
          </Button>
        )}
      </div>
    </div>
  );
}

function Assignments(props) {
  const { value, date, assignments = [], state, dispatch, assign, handleUnassign } = props;

  const components = {
    [0]: (
      <AssignedPickList
        assignments={assignments.filter((a) => a.isAssigned)}
        handleUnassign={handleUnassign}
      />
    ),
    [1]: (
      <PendingPickList
        date={date}
        assignments={assignments.filter((a) => !a.isAssigned)}
        state={state}
        dispatch={dispatch}
        assign={(data) => assign(data)}
      />
    ),
  };

  return components[value];
}
