import React from 'react';
import {
  format0,
  formatCurrency0,
  formatDateHumanReadable,
  formatDayTime,
  formatDistance,
  formatTimeSpan,
} from 'utils/utils';
import { useGState } from 'state/store';
import { Box, Divider, CircularProgress, IconButton, Menu, MenuItem } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import BatteryChargingFullIcon from '@material-ui/icons/BatteryChargingFull';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CropSquareIcon from '@material-ui/icons/CropSquare';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { navigate } from 'components/Link';
import { CardContainer } from 'delivery/execution/Payments';

export default function AssignedPickList(props) {
  const { assignments, handleUnassign } = props;
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-3 p-4">
      {assignments?.map((assignment, index) => (
        <AssignmentCard key={index} assignment={assignment} handleUnassign={handleUnassign} />
      ))}
    </div>
  );
}

function AssignmentCard({ assignment, handleUnassign }) {
  const { id, approved, picklist, deliveryAgent, metrics, date, vehicle, profile } = assignment;
  const { delivered, partialDelivered, failed, total, sales, weight } = metrics;
  const timeDiff =
    (new Date().getTime() - new Date(deliveryAgent?.dashboard?.lastUpdated).getTime()) / 1000; // in seconds
  const pastAssignment = new Date().setHours(0, 0, 0, 0) - new Date(date).setHours(0, 0, 0, 0) > 0;

  const getLastActiveStatus = () => {
    if (timeDiff < 0) return '--';

    return deliveryAgent?.dashboard?.mobileStatus?.value === 'on' ? (
      <p className={`font-medium text-green flex items-center`}>
        <FiberManualRecordIcon fontSize="inherit" />
        <span className="ml-2">Active</span>
      </p>
    ) : (
      <p className={`font-medium text-inactive flex items-center`}>
        {timeDiff > 0 ? formatTimeSpan(timeDiff) + ' ago' : '--'}
      </p>
    );
  };

  const getBatteryStatus = (val) => {
    return val ? Math.max(val, 0) + ' %' : '--';
  };

  return (
    <CardContainer twCls="px-5 flex flex-row justify-between">
      <div className="flex-grow">
        <p className="font-medium text-base mb-5">{profile.name}</p>
        <div
          className="flex flex-row cursor-pointer"
          onClick={() => navigate(`/dl/retailers/${id}`)}
        >
          <div className="bg-inactive-light px-4 py-3 rounded-lg mr-8">
            <div className="flex flex-col items-center justify-between">
              <p className="font-medium text-inactive whitespace-nowrap">
                {formatDateHumanReadable(picklist?.date)}
              </p>
              <p className="font-semibold text-lg mt-1 mb-2">{picklist.name}</p>
              {!pastAssignment && (
                <>
                  <div className="bg-white py-1 px-3 rounded-lg mb-2">{getLastActiveStatus()}</div>
                  <p className="font-medium text-inactive">
                    <BatteryChargingFullIcon
                      fontSize="inherit"
                      classes={{ root: 'transform rotate-90 mr-1' }}
                    />
                    {getBatteryStatus(deliveryAgent?.dashboard?.batteryLevel?.value)}
                  </p>
                </>
              )}
            </div>
          </div>
          <div>
            <p className="font-medium text-lg">{deliveryAgent.name}</p>
            <p className="font-medium text-inactive text-sm">
              {vehicle.vehicleType + ', ' + vehicle.vehicleNo}
            </p>
            <div className="flex justify-between flex-wrap mt-10">
              <p className="font-medium text-inactive mr-2">{formatCurrency0(sales)}</p>
              <p className="font-medium text-inactive">{format0(weight) + ' Kg'}</p>
            </div>
          </div>
        </div>
        <div className="flex mt-10 items-center">
          <RadioButtonUncheckedIcon fontSize="inherit" />
          <Divider className="w-10/12" />
          <CropSquareIcon fontSize="inherit" />
        </div>
        <div className="flex mt-4 items-center justify-between">
          <CaptionWithValue
            text="Start Time"
            value={
              deliveryAgent.dashboard?.branchStartEnd?.start
                ? formatDayTime(new Date(deliveryAgent.dashboard?.branchStartEnd?.start))
                : '--'
            }
          />
          <CaptionWithValue
            text="Distance"
            value={formatDistance(deliveryAgent?.dashboard?.distanceTravelled?.value) + ' Km'}
          />
          <CaptionWithValue
            text="End Time"
            value={
              deliveryAgent.dashboard?.branchStartEnd?.end
                ? formatDayTime(new Date(deliveryAgent.dashboard?.branchStartEnd?.end))
                : '--'
            }
          />
        </div>
      </div>
      <div className="flex-grow-0 ml-6">
        <div className="flex font-normal text-sm mb-5 items-center">
          {approved ? (
            <CheckCircleOutlineIcon fontSize="inherit" classes={{ root: 'text-green' }} />
          ) : (
            <QueryBuilderIcon fontSize="inherit" classes={{ root: 'text-orange' }} />
          )}
          <div className="mx-1 capitalize whitespace-nowrap">{`Trip ${
            approved ? '' : 'Un'
          }approved`}</div>
          <LongMenu handleUnassign={() => handleUnassign(id)} approved={approved} />
        </div>
        <div className="flex flex-col justify-between">
          <Progress delivered={delivered + partialDelivered + failed} total={total} />
          <MetricsCard delivered={delivered} failed={failed} partial={partialDelivered} />
        </div>
      </div>
    </CardContainer>
  );
}

function LongMenu(props) {
  const { handleUnassign, approved } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const permissions = useGState((s) => s.permission);
  const userHasAssignPermission = permissions.some((p) => p && p.code === 'tms.execution.assign');
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const unAssign = () => {
    handleUnassign();
    handleClose();
  };
  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        classes={{ root: 'p-0' }}
      >
        <MoreVertIcon fontSize="inherit" size={25} />
      </IconButton>
      <Menu
        id="long-menu"
        classes={{ list: 'p-0 bg-dark_blue text-white' }}
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={unAssign} disabled={approved || !userHasAssignPermission}>
          Unassign
        </MenuItem>
      </Menu>
    </div>
  );
}
function CaptionWithValue(props) {
  const { text, value } = props;
  return (
    <div className="flex flex-col text-center">
      <p className="text-inactive font-medium">{text}</p>
      <p className="font-medium">{value}</p>
    </div>
  );
}

function Progress(props) {
  const { delivered = 0, total } = props;

  return delivered < total ? (
    <div className="self-center items-center">
      <Box position="relative" display="inline-flex">
        <CircularProgress
          size={48}
          thickness={2}
          variant="static"
          value={(delivered * 100) / (total || 1)}
          classes={{ svg: 'border-solid border-2 rounded-full' }}
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <p
            className={`text-theme font-semibold ${
              delivered > 10 || total > 10 ? 'text-xs' : 'text-lg'
            }`}
          >{`${delivered}/${total}`}</p>
        </Box>
      </Box>
    </div>
  ) : (
    <div className="self-center rounded-full p-3 items-center bg-success-green">
      <p className="text-white font-semibold text-lg">{`${delivered}/${total}`}</p>
    </div>
  );
}
function MetricsCard(props) {
  const { delivered = 0, failed = 0, partial = 0 } = props;

  const data = [
    { val: delivered, color: 'text-success', text: 'Success' },
    { val: partial, color: 'text-orange', text: 'Partial' },
    { val: failed, color: 'text-danger', text: 'Failed' },
  ];

  const renderContent = () => {
    return data.map((d) => (
      <div
        key={d.text + d.val}
        className="flex flex-col mt-2 justify-center py-1 border border-solid rounded-lg items-center"
      >
        <p className={`font-medium ${d.val ? d.color : 'text-inactive'}`}>{d.val}</p>
        <p className={`font-medium ${d.val ? d.color : 'text-inactive'}`}> {d.text}</p>
      </div>
    ));
  };

  return renderContent();
}
