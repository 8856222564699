import produce from 'immer';

export const actions = {
  INIT: 'INIT',
  ADD_NEW_PICKLIST: 'ADD_NEW_PICKLIST',
  PREPARE_VEHICLE_LIST: 'PREPARE_VEHICLE_LIST',
  PREPARE_DA_LIST: 'PREPARE_DA_LIST',
  UPDATE_SELECTED_PLAN_PROFILE: 'UPDATE_SELECTED_PLAN_PROFILE',
};

const assignmentReducer = (state, [action, data]) => {
  return produce(state, (draft) => {
    switch (action) {
      case actions.INIT: {
        const uniqueProfiles = new Set();
        const profileList = new Array();

        for (let i = 0; i < data.length; i++) {
          const pro = data[i].profile;
          if (uniqueProfiles.has(pro.id)) continue;

          profileList.push({ label: pro.name || pro.id, value: pro.id });
          uniqueProfiles.add(pro.id);
        }

        profileList.push({ value: 1, label: 'All Plan Profiles' });

        return {
          ...data,
          actualPickList: data,
          filteredPickList: data,
          profileList: profileList,
          selectedPlanProfile: profileList.find((item) => item.value === 1),
          primaryDaList: [],
          secondaryDaList: [],
          vehicleList: [],
        };
      }

      case actions.ADD_NEW_PICKLIST:
        {
          const picklists = [data, ...state.actualPickList];

          draft.actualPickList = picklists;
          if (state.selectedPlanProfile.value === 1) {
            draft.filteredPickList = picklists;
          } else {
            draft.filteredPickList = picklists.filter(
              (item) => item.profile.id === state.selectedPlanProfile.value
            );
          }
        }
        break;

      case actions.PREPARE_VEHICLE_LIST:
        {
          //console.log('Vehicle data : ', data);
          const list = data?.map((item) => {
            return {
              value: item.id,
              label: `${item.data.vehicleNo}(${item.data.vehicleType})`,
            };
          });
          //console.log('Vehicle List : ', list);
          draft.vehicleList = list;
        }
        break;

      case actions.PREPARE_DA_LIST:
        {
          const daList = data?.map((item) => {
            return {
              value: item.id,
              label: item.name || item.id,
            };
          });
          draft.primaryDaList = daList;
          draft.secondaryDaList = daList;
        }
        break;

      case actions.UPDATE_SELECTED_PLAN_PROFILE:
        {
          // console.log('Selected plan profile : ', data);
          draft.selectedPlanProfile = data;

          if (data.value === 1) {
            draft.filteredPickList = state.actualPickList;
          } else {
            const list = state.actualPickList.filter((item) => item.profile.id === data.value);
            draft.filteredPickList = list;
          }
        }
        break;

      default:
        return state;
    }
  });
};

export default assignmentReducer;
