import { Router } from '@reach/router';
import Layout from 'components/Layout';
import DeliveryAssignment from 'delivery/assignment/DeliveryAssignment';
import React from 'react';
import { ReactQueryConfigProvider } from 'react-query';

export default (props) => (
  <Layout {...props} hideDateFilter>
    <ReactQueryConfigProvider
      config={{ suspense: false, throwOnError: false, useErrorBoundary: false }}
    >
      <Router>
        <DeliveryAssignment path="/dl/execution" />
        <DeliveryAssignment path="/dl/execution/:branchId" />
      </Router>
    </ReactQueryConfigProvider>
  </Layout>
);
