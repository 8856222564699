import { GridList, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import PickListItem from './PickListItem';

const useStyles = makeStyles(() => ({
  gridList: {
    // width: '100%',
    // height: '100%',
    paddingTop: '1.25rem',
  },
  textPast: {
    fontSize: '1.125rem',
    color: '#31405c',
    opacity: 6.5,
    marginTop: '2.375rem',
    marginStart: '0.438rem',
  },
}));

export default function PendingPickList(props) {
  const { date, assignments, state, assign } = props;
  const classes = useStyles();
  //console.log(state?.profileList);
  // useEffect(() => {
  //   console.log('Selected plan profile in state : ', state.selectedPlanProfile);
  // }, [state]);

  // useEffect(() => {
  //   console.log('Vehicle list : ', state.vehicleList);
  // }, [state.vehicleList]);

  return (
    <div className="m-4">
      <div class="grid gap-2 md:grid-cols-3 sm:grid-cols-1">
        {assignments?.map((assignment) => {
          if (assignment.picklist.date === date) {
            return (
              <PickListItem
                key={assignment.picklistId}
                assignment={assignment}
                primaryDaList={state?.primaryDaList}
                secondaryDaList={state?.secondaryDaList}
                vehicleList={state?.vehicleList}
                assign={(data) => assign(data)}
              />
            );
          }
        })}
      </div>

      {/* Past 7 days section  */}
      <Typography className={classes.textPast}>Past 7 days</Typography>
      <GridList className={classes.gridList} cellHeight="auto" spacing={5} cols={3}>
        {assignments?.map((assignment) => {
          if (assignment.picklist.date !== date) {
            return (
              <PickListItem
                key={assignment.picklistId}
                assignment={assignment}
                primaryDaList={state?.primaryDaList}
                secondaryDaList={state?.secondaryDaList}
                vehicleList={state?.vehicleList}
                assign={(data) => assign(data)}
              />
            );
          }
        })}
      </GridList>
    </div>
  );
}
